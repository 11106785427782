import { App, Modal, Skeleton } from 'antd';
import React, { createContext, ReactNode, Suspense, useContext, useState } from 'react';
import { ModalNames } from './modal-names';

export interface ModalOptions {
  open?: boolean;
  title?: string | JSX.Element;
  content?: ReactNode;
  onOk?: () => void; // Callback function when confirming
  okText?: string;
  cancelText?: string;
  footer?: JSX.Element;
  data?: any; // Unique data for each modal, type has to be set for each modal individually
  className?: string;
}

export interface ModalItem {
  name: ModalNames;
  component: React.FC<ModalOptions>;
}

interface ModalContextType {
  openModal: (name: ModalNames, options?: ModalOptions) => void;
  closeModal: () => void;
  confirm: (options: ModalOptions) => void;
  error: (options: ModalOptions) => void;
  warning: (options: ModalOptions) => void;
  info: (options: ModalOptions) => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

interface ModalProviderProps {
  modals: ModalItem[];
  children: ReactNode;
}

export const ModalProvider: React.FC<ModalProviderProps> = ({ modals, children }) => {
  const [openModals, setOpenModals] = useState<{ [key in ModalNames]?: ModalOptions }>({});
  const { modal } = App.useApp(); // Access the modal object dynamically via useApp()

  const openModal = (name: ModalNames, options: ModalOptions = {}) => {
    setOpenModals(prev => ({
      ...prev,
      [name]: { ...options, open: true },
    }));
  };

  // Function to close any open modal(s)
  const closeModal = () => {
    setOpenModals(prev => {
      const updatedModals = { ...prev };
      Object.keys(updatedModals).map(key => {
        if (updatedModals[key as ModalNames]?.open) {
          updatedModals[key as ModalNames] = { ...updatedModals[key as ModalNames], open: false };
        }
      });
      return updatedModals;
    });
  };

  // Modal methods using modal from ant useApp()
  const confirm = (options: ModalOptions) => modal.confirm(options);
  const error = (options: ModalOptions) => modal.error(options);
  const warning = (options: ModalOptions) => modal.warning(options);
  const info = (options: ModalOptions) => modal.info(options);

  return (
    <ModalContext.Provider value={{ openModal, closeModal, confirm, error, warning, info }}>
      {children}
      {modals.map(({ name, component: Component }) => {
        const modalProps = openModals[name];

        if (!modalProps) return null; // Only render if modalProps exist

        const { open, ...restModalProps } = modalProps;

        return (
          <Modal
            key={name}
            open={open}
            onCancel={() => closeModal()}
            destroyOnClose // Ensures that modal content is removed from the DOM when closed
            footer={null}
            maskClosable={false}
            afterClose={() => {
              setOpenModals(prev => {
                const updated = { ...prev };
                delete updated[name]; // Clean up after close animation
                return updated;
              });
            }}
            {...restModalProps}
          >
            <Suspense fallback={<Skeleton />}>
              <Component {...restModalProps} />
            </Suspense>
          </Modal>
        );
      })}
    </ModalContext.Provider>
  );
};
