import { ConfigProvider } from 'antd';
import deDE from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import i18n from 'i18next';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { App as AntdApp } from 'antd'; // Import Ant Design's App component
import { modals } from '~/ui/modal/modal-config';
import { ModalProvider } from '~/ui/modal/modal-context';
import store from './store/store';

import './i18n';
import './tailwind.css';

// set the locale for dayjs to the default locale in i18next
dayjs.locale(i18n.language);

// set the locale for Ant to the default locale in i18next
const getAntLocale = () => {
  switch (i18n.language) {
    case 'de':
      return deDE;
    case 'en':
      return enUS;
    default:
      return deDE;
  }
};

document.querySelectorAll<HTMLElement>('[data-component]').forEach(element => {
  const componentName = element.dataset.component;

  if (componentName) {
    import(`./components/${componentName}.tsx`).then(({ default: Component }) => {
      const props: Record<string, string> = Object.fromEntries(
        Object.entries(element.dataset)
          .filter(([key, value]) => key !== 'component' && typeof value === 'string')
          .map(([key, value]) => [key, value as string])
      );

      const root = createRoot(element);

      root.render(
        <Provider store={store}>
          <ConfigProvider
            locale={getAntLocale()}
            theme={{
              token: {
                // Seed Token
                colorPrimary: '#219e3e',
                borderRadius: 5,
              },
            }}
          >
            {/* <AntdApp> is needed to get the Modal.methods() from ant design working*/}
            <AntdApp>
              <ModalProvider modals={modals}>
                <Component {...props} />
              </ModalProvider>
            </AntdApp>
          </ConfigProvider>
        </Provider>
      );
    });
  }
});
