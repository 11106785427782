// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../../node_modules/tailwindcss/index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Shadcn Configs ---- Start */
@plugin 'tailwindcss-animate';

@custom-variant dark (&:is(.dark *));

:root {
  --radius: 0.6rem;
}

@theme inline {
  --radius-sm: calc(var(--radius) - 4px);
  --radius-md: calc(var(--radius) - 2px);
  --radius-lg: var(--radius);
  --radius-xl: calc(var(--radius) + 4px);
}
/* Shadcn Configs ---- End */
`, "",{"version":3,"sources":["webpack://./core/React/tailwind.css"],"names":[],"mappings":"AAEA,8BAA8B;AAC9B,6BAA6B;;AAE7B,oCAAoC;;AAEpC;EACE,gBAAgB;AAClB;;AAEA;EACE,sCAAsC;EACtC,sCAAsC;EACtC,0BAA0B;EAC1B,sCAAsC;AACxC;AACA,4BAA4B","sourcesContent":["@import 'tailwindcss';\n\n/* Shadcn Configs ---- Start */\n@plugin 'tailwindcss-animate';\n\n@custom-variant dark (&:is(.dark *));\n\n:root {\n  --radius: 0.6rem;\n}\n\n@theme inline {\n  --radius-sm: calc(var(--radius) - 4px);\n  --radius-md: calc(var(--radius) - 2px);\n  --radius-lg: var(--radius);\n  --radius-xl: calc(var(--radius) + 4px);\n}\n/* Shadcn Configs ---- End */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
